.main {
  width: 100%;
  height: 100%;
  .main-left {
    height: 100%;
    float: left;
  }
  .main-content {
    float: left;
    margin-left: 10px;
    height: 100%;
  }
  .main-right {
    float: left;
    margin-left: 10px;
    height: 100%;
  }
  .main-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
    text-align: right;
    height: auto;
  }
}
