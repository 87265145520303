.ant-menu-dark {
  &.ant-menu-inline {
    .ant-menu-item-selected {
      background-color: #5f5f5f !important;
    }
  }
}
.custom-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-layout-sider-collapsed {
  overflow-y: initial !important;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  color: white;
  i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 1px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;
    &.bottom {
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
    }
    &.on {
      background-color: #6cc788;
    }
  }
  img {
    border-radius: 500px;
    width: 100%;
  }
}
.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box;
  &.active {
    right: -2px;
  }
  .sw-btn {
    position: absolute;
    left: -43px;
    top: -1px;
    padding: 10px 15px;
    z-index: 1045;
    border: 1px solid rgba(120, 120, 120, 0.1);
    border-right-width: 0;
    background-clip: padding-box;
  }
}
